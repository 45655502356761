<template>
  <div :class="[$style.container, showHeader && $style.withHeader]">
    <div :class="$style.header" v-if="showHeader">
      <div :class="$style.headerContainer">
        <div :class="$style.headerMain">
          <span :class="$style.title"><slot name="title" /></span>
          <span v-if="$slots.subtitle" :class="$style.subtitle">
            <slot name="subtitle" />
          </span>
        </div>
        <div v-if="$slots.aside">
          <slot name="aside" />
        </div>
      </div>
      <slot name="header" />
    </div>
    <div :class="$style.body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutTabsWrap',
  computed: {
    showHeader() {
      return this.$slots.title || this.$slots.header
    }
  }
}
</script>

<style lang="scss" module>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.header {
  position: relative;
  padding: 2em 2em 2.6em;
  height: 8.3em;
  .title {
    display: block;
    font-family: var(--primary-font);
    font-style: var(--primary-font-style);
    font-weight: 700;
    font-size: 1.8em;
    line-height: 144%;
    text-align: left;
    letter-spacing: -0.02em;
  }
  .subtitle {
    font-weight: 400;
    font-size: 1.4em;
    line-height: 143%;
    color: #868686;
  }
}
.headerContainer {
  display: flex;
  align-items: center;
}
.headerMain {
  flex: 1 1;
}
.body {
  flex: 1 1;
  height: 100%;
}
.withHeader .body {
  flex: 1 1;
  height: calc(100% - 8.3em);
}
</style>
